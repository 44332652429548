@import '../../../../theme.scss';

.CorrectedIngredientAutoComplete__ {
  &autocomplete {
    & .MuiInputLabel-outlined {
      padding-top: $themeSpacing !important;
    }
    & .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot {
      padding: themeSpacing !important;
      & .MuiInputBase-fullWidth {
        width: 95% !important;
      }
    }
    & .MuiAutocomplete-endAdornment {
      & .MuiIconButton-root.MuiAutocomplete-clearIndicator {
        display: none !important;
      }
    }
  }
}
