@import '../../../../../theme.scss';

.CellRenderer__ {
  &outputColumn {
    min-width: 500px !important;
  }

  &inputColumn {
    min-width: 300px !important;
  }

  &changeByColumn {
    max-width: 150px !important;
    overflow-wrap: break-word !important;
  }
}
