@import url('./fonts.scss');

@media print {
  @page {
    size: A4;
    margin: 0;
    padding: 0;
  }
  html,
  body {
    position: relative;
    width: 210mm;
    height: 297mm;
    max-width: 100%;
    max-height: 97%;
    margin: 0;
    padding: 0;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

[role='tooltip'] {
  z-index: 1000;
}
