.Sidebar__ {
  &drawer {
    flex-shrink: 0 !important;
    transition: width 0.2s !important;
  }

  &drawerPaper {
    width: 240px !important;
    flex-shrink: 0 !important;
    & .MuiListItemText-root {
      white-space: break-spaces !important;
    }
  }

  &drawerClosed {
    width: 58px !important;
    & :hover {
      width: 260 !important;
    }
  }
}
