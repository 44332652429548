.DocLink__ {
  &link {
    color: white !important;
  }

  &buttonContainer {
    display: inline-flex !important;
    & > a {
      display: inline-flex !important;
    }
  }

  &root {
    width: 100% !important;
  }

  &iconButton {
    padding: 0 !important;
  }

  &icon {
    font-size: 18px !important;
  }
}
