@import '../../../../theme.scss';

.RowCellStatus__ {
  &warning {
    color: $systemWarning;
  }
  &success {
    color: $systemSuccess;
  }
  &grayscale4 {
    color: $grayscale4;
  }
  &danger {
    color: $systemDanger;
  }
}
