@import '../theme.scss';

// copied from src/hooks/styles/use-card-style.ts

.card__ {
  &container {
    max-width: 100% !important;
    height: 100% !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between !important;
    & > a {
      flex-grow: 1px !important;
    }
    > div.MuiCardActions-root > button.MuiButton-textPrimary {
      @include themed {
        color: getThemeVariable('primaryMain') !important;
      }
    }
  }

  &headerContent {
    display: contents !important;
    width: 100% !important;
  }

  &title {
    overflow: hidden !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
    max-width: 100% !important;
    width: 100% !important;
  }

  &version {
    color: #999 !important;
  }

  &media {
    margin-bottom: 30px !important;
  }

  &image {
    background: #eee !important;
    height: 112px !important;
    margin: -15px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    overflow: hidden !important;
  }

  &description {
    display: block !important;
    overflow: hidden !important;
    white-space: normal !important;
    text-overflow: ellipsis !important;
    max-width: 300px !important;
    line-height: 30px !important;
    height: 60px !important;
    max-height: 60px !important;
  }

  &highlighted {
    color: white !important;
    @include themed {
      background: getThemeVariable('primaryMain') !important;
    }
  }
}
