@import '../../theme.scss';

.contextForm__ {
  &buttonProgress {
    color: black !important;
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    margin-top: -12px !important;
    margin-left: -12px !important;
  }

  &actions {
    text-align: right !important;
    margin: -15px -20px !important;
    margin-top: 20px !important;
    padding: 20px !important;
    background: $form-background !important;
  }

  &gridContainer {
    padding: 8px !important;
  }

  &gridItem {
    padding: 4px !important;
  }
}
