@import '../theme.scss';

.ExternalLink__ {
  &jobHandlerLinkContainer {
    display: flex !important;
    max-width: 350px !important;
    width: 100% !important;
    margin-top: (3 * $themeSpacing) !important;
    padding: $themeSpacing !important;
    border: 1px solid $grayscale1 !important;
    background-color: white !important;
    border-radius: 3px !important;
    align-items: center !important;
    justify-content: center !important;
    cursor: pointer !important;
  }
}
