@import '../theme.scss';

.common__ {
  // copied from src/hooks/styles/use-styles.ts

  &root {
    display: flex !important;
  }

  &transferListTable {
    min-width: 650px !important;
    thead tr > th {
      padding: (2 * $themeSpacing) !important;
    }
  }

  &transferListButton {
    margin: (0.5 * $themeSpacing) (0.5 * $themeSpacing) !important;
  }

  &button {
    margin: $themeSpacing !important;
  }

  &flowButtons {
    background-color: white !important; // theme.palette.background.default;
    position: fixed !important;
    margin-left: (-3 * $themeSpacing) !important;
    padding: 0 !important;
    z-index: ($themeZIndexDrawer + 1) !important;
    bottom: 0 !important;
    top: auto !important;
    width: 100% !important;
  }

  &textField {
    margin-left: $themeSpacing !important;
    margin-right: $themeSpacing !important;
    width: 100% !important;
  }

  &largeTextField {
    margin-left: $themeSpacing !important;
    margin-right: $themeSpacing !important;
  }

  &menu {
    width: 200px !important;
  }

  &dataVersionsContainer {
    flex-direction: row !important;
    justify-content: flex-start !important;
    flex-wrap: nowrap !important;
    align-items: baseline !important;
    & > div {
      width: 100% !important;
      & > div {
        width: 100% !important;
        & > div {
          text-align: left !important;
        }
      }
    }
    .MuiAutocomplete-root.MuiAutocomplete-fullWidth {
      width: 99% !important;
    }
  }

  &configSection {
    background-color: white !important; // theme.palette.background.default;
    padding: (2 * $themeSpacing) !important;
    width: 100% !important;
  }

  &textAreaLabel {
    margin: $themeSpacing !important;
    font-size: 17px !important;
  }

  &textArea {
    margin-left: $themeSpacing !important;
    margin-right: $themeSpacing !important;
    width: 100% !important;
    resize: none !important;
  }

  &jobHeader {
    display: flex !important;
    justify-content: space-between !important;
    & svg {
      cursor: pointer !important;
    }
  }

  &noScrollbarPaper {
    // to avoid extra scroll bars
    overflow: hidden !important;
  }

  &scrollableTransferList {
    width: 316px !important;
    height: 60vh !important;
    // to avoid extra scroll bars in the tag window
    overflow: auto !important;
  }

  &buttonProgress {
    color: black !important;
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    margin-top: -12px !important;
    margin-left: -12px !important;
  }

  &categoryGroup {
    font-weight: bold !important;
    padding: 4px 10px !important;
  }

  &lastTagIList {
    border-bottom: 1px solid black !important;
  }

  &pageTitle {
    margin: (3 * $themeSpacing) !important;
    margin-top: 0 !important;
  }

  &transferList {
    width: 550px !important;
    height: 60vh !important;
    // to avoid extra scroll bars in the tag window
    overflow: hidden !important;
  }

  &dataSourceVersionListDropdownWrapper {
    min-height: 98px !important;
    margin-right: (2 * $themeSpacing) !important;
    z-index: 1 !important;
    box-shadow: none !important;
  }

  &dataSourceVersionList {
    max-height: 65vh !important;
    overflow: auto !important;
  }

  &ingredientTableDataGrid {
    &.MuiDataGrid-root {
      min-height: 100px !important;
      .MuiDataGrid-main {
        min-height: 200px !important;
        .MuiDataGrid-columnsContainer {
          line-height: 46px !important;
          font-size: 16px !important;
        }
      }
    }
  }

  &sectionTitle {
    margin-bottom: (2 * $themeSpacing) !important;
    margin-top: 0 !important;
  }

  // copied from src/features/project/components/Config.tsx

  &projectInfoContainer {
    flex-direction: row !important;
    align-items: center !important;
    flex-wrap: wrap !important;
  }

  &chip {
    margin: 2px !important;
  }

  &chips {
    display: flex !important;
    flex-wrap: wrap !important;
  }
}
