@import './theme.scss';

.App {
  text-align: center !important;
}

.App-logo {
  height: 40vmin !important;
}

.App-header {
  background-color: #282c34 !important;
  min-height: 100vh !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: calc(10px + 2vmin) !important;
  color: white !important;
}

.App-link {
  color: #09d3ac !important;
}

div.loader__div .MuiCircularProgress-root.MuiCircularProgress-colorPrimary svg.MuiCircularProgress-svg {
  @include themed {
    color: getThemeVariable('primaryMain') !important;
  }
}

.MuiTooltip-popper .MuiTooltip-tooltip {
  background-color: #444;
}

.MuiTypography-h4 {
  @include greatPrimer;
}

.MuiTypography-h5 {
  @include pica;
}

.MuiTypography-h6 {
  @include pica_strong;
}

body,
.MuiTypography-body1 {
  // copied from layout/themes.ts
  @include body;
}

.MuiTypography-body2 {
  @include body_strong;
}

.MuiButtonBase-root {
  text-transform: none !important;
}

.MuiButton-containedSecondary:not(:disabled),
.MuiButton-containedPrimary:not(:disabled),
.MuiLinearProgress-barColorPrimary {
  @include themed {
    background-color: getThemeVariable('primaryMain') !important;
  }
}

.MuiLinearProgress-root.MuiLinearProgress-colorPrimary {
  @include themed {
    background-color: getThemeVariable('primaryLight') !important;
  }
}

.MuiButton-textPrimary:not(:disabled),
.MuiButton-textSecondary:not(:disabled) {
  @include themed {
    color: getThemeVariable('primaryMain') !important;
  }
}

.MuiInputBase-root.MuiInput-root:after {
  @include themed {
    border-bottom: 2px solid getThemeVariable('primaryMain') !important;
  }
}

.MuiFormLabel-colorPrimary.Mui-focused {
  @include themed {
    color: getThemeVariable('primaryMain') !important;
  }
}

.Login__box {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 50%;
  left: 50%;
  opacity: 0;

  transform: translate(-50%, -50%);
  animation: fadeIn 0.5s 0.5s ease-in-out forwards;

  &__button {
    @include themed {
      background: getThemeVariable('primaryMain') !important;
    }
    display: flex;
    color: white;
    margin: 0 auto;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.Login__box__logo {
  margin-bottom: 2rem;
  width: 500px;

  & svg {
    width: 100%;
    height: 100%;
  }
}

.App__error_background {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
}

.App__error_popup {
  position: fixed;
  top: 50%;
  left: 50%;
  padding: 2rem;
  transform: translate(-50%, -50%);
  z-index: 10000;
  background: white;

  &__header {
    display: flex;
    justify-content: flex-end;
  }
}

.App__error_popup__title {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  font-size: 1.2rem !important;
  font-weight: bold !important;

  & svg {
    margin-right: 0.5rem;
  }
}

.App__error_popup__text {
  margin-bottom: 1rem;

  &__url {
    display: block;
    margin-top: 1rem;
    background: #eee;
    color: #222 !important;
    font-size: 1rem !important;
    padding: 0.5rem;
    border-radius: 4px;
  }

  & span {
    font-size: 0.8rem;
    color: red;
  }
}

.App__error_popup__footer__button {
  margin-right: 1rem;
}

.cfi-11xur9t-MuiPaper-root-MuiTableContainer-root {
  overflow-x: visible;
}
