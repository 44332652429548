@import '../theme.scss';

// copied from src/admin/projects/ProjectFlow/ConceptGeneration/ConfigureConceptGenerationRun/useRunConfigStyles.ts

.RunConfig__ {
  &stepContainer {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    width: 100% !important;
  }

  &stepTitle {
    margin: (3 * $themeSpacing) 0 !important;
    display: flex !important;
    width: 100% !important;
  }

  &wizardWrapper {
    padding: (2 * $themeSpacing) !important;
  }

  &flowData {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }

  &dialogActions {
    display: flex !important;
    align-items: center !important;
    margin: 0 !important;
    justify-content: center !important;
    margin-top: 10px !important;
    text-align: center !important;
    padding: 10px !important;
    position: fixed !important;
    z-index: 1202 !important;
    top: auto !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
  }

  &backButton {
    margin-right: $themeSpacing !important;
  }

  &compositionTemplateContainer {
    width: 100% !important;
  }

  &ingredientsNumberBlock {
    display: flex !important;
    align-items: center !important;
    min-width: 300px !important;
    margin-bottom: (2 * $themeSpacing) !important;
    & > div {
      display: flex !important;
      border: 1px grey !important;
      border-radius: (0.5 * $themeSpacing) !important;
      align-items: center !important;
      margin-left: (3 * $themeSpacing) !important;
    }
  }

  &compositionTable {
    min-width: 900px !important;
    min-height: 400px !important;
    .MuiDataGrid-cell {
      padding: 0 !important;
    }
  }

  &actionBlock {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }

  &metricItemsContainer {
    width: 450px !important;
  }

  &metricItem {
    display: flex !important;
    width: 100% !important;
    justify-content: space-between !important;
    & > span {
      width: 150px !important;
    }
  }

  &territoryDefinitionDropdown {
    display: flex !important;
    align-items: center !important;
    min-width: 200 !important;
    max-width: 300 !important;
    margin-bottom: (2 * $themeSpacing) !important;
  }
}
