@import '../theme.scss';

.SubMenu__ {
  &icon {
    &.MuiListItemIcon-root {
      min-width: (5 * $themeSpacing) !important;
      & .MuiSvgIcon-root {
        &:hover {
          flex-shrink: 1 !important;
        }
      }
    }
  }

  &sidebarIsOpen {
    & a {
      padding-left: (4 * $themeSpacing) !important;
      transition: padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms !important;
    }
  }

  &sidebarIsClosed {
    & a {
      padding-left: (2 * $themeSpacing) !important;
      transition: padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms !important;
    }
  }
}
