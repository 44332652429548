@import '../theme.scss';

// copied from src/admin/projects/ProjectFlow/_utils/correctionTableStyles.ts

.CorrectionTable__ {
  &errorRow {
    border: 2px solid !important;
    border-color: $systemDanger !important;
    background-color: rgba($systemDanger, 60%); // must not be important
    border-radius: 4 !important;
    .MuiDataGrid-cell {
      &.message {
        font-size: 15px !important;
        .MuiAlert-icon {
          margin-right: (0.5 * $themeSpacing) !important;
          line-height: 16px !important;
        }
        .MuiAlert-root {
          font-size: 15px !important;
          line-height: 17px !important;
          margin-right: (0.5 * $themeSpacing) !important;
          .MuiSvgIcon-root {
            width: 36px !important;
            height: 40px !important;
          }
        }
      }
    }
    &.MuiDataGrid-row.Mui-selected {
      background-color: rgba($systemDanger, 60%) !important;
      &:hover {
        background-color: rgba($systemDanger, 40%) !important;
      }
    }
  }

  &warningRow {
    border: 2px solid !important;
    border-color: $systemWarning !important;
    background-color: rgba($systemWarning, 60%); // must not be important
    border-radius: 4px !important;
    .MuiDataGrid-cell {
      &.message {
        font-size: 15px !important;
        .MuiAlert-icon {
          margin-right: (0.5 * $themeSpacing) !important;
          line-height: 16px !important;
        }
        .MuiAlert-root {
          font-size: 15px !important;
          line-height: 17px !important;
          margin-right: (0.5 * $themeSpacing) !important;
          border-color: rgb(239, 199, 61);
          .MuiSvgIcon-root {
            width: 36px !important;
            height: 40px !important;
          }
        }
      }
    }
    &.MuiDataGrid-row.Mui-selected {
      background-color: rgba($systemWarning, 60%) !important;
      &:hover {
        background-color: rgba($systemWarning, 40%) !important;
      }
    }
  }

  &grid {
    &.MuiDataGrid-root {
      .MuiDataGrid-main {
        .MuiDataGrid-columnsContainer .MuiDataGrid-columnHeaderWrapper {
          .MuiDataGrid-columnHeader {
            max-width: none !important;
            .MuiDataGrid-columnHeaderTitleContainer {
              padding: 0 !important;
              .editableHeaderIcon {
                color: $systemWarning !important;
              }
              .MuiDataGrid-columnHeaderTitle {
                white-space: normal !important;
                text-align: center !important;
                vertical-align: middle !important;
                line-height: 24px !important;
              }
            }
          }
        }
      }
    }
    .MuiDataGrid-cell {
      &.MuiDataGrid-cell--editable {
        background-color: rgba($systemWarning, 5%) !important;
      }
    }
  }
}
