@import '../../../theme.scss';

.contextVersionsForm__ {
  &circularProgress {
    color: black !important;
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    margin-top: -12px !important;
    margin-left: -12px !important;
  }

  &backdrop {
    z-index: ($themeZIndexDrawer + 1) !important;
    color: #fff !important;
    position: absolute !important;
  }

  &formWrapper {
    position: relative !important;
  }

  &footerActionsBar {
    margin-top: 10px !important;
    text-align: right !important;
    padding: 10px !important;
    position: fixed !important;
    z-index: ($themeZIndexDrawer + 1) !important;
    top: auto !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    button {
      margin-left: 10px !important;
    }
  }
}
