@import '../theme.scss';

.Layout__ {
  &content {
    background: $grayscale05 !important;
    min-height: 100vh !important;
  }

  &appBar {
    z-index: ($themeZIndexDrawer + 1) !important;
    // from frontends/cfi/src/layout/themes.ts .MuiAppBar {
    @include themed {
      color: #fff;
      background-color: getThemeVariable('primaryMain');
    }
    .Layout__menuButton {
      margin-right: (2 * $themeSpacing) !important;
      svg.MuiSvgIcon-root {
        color: inherit !important;
      }
    }
  }

  &root {
    flex-grow: 1 !important;
  }

  &title {
    flex-grow: 1 !important;
  }
}

:not(.Mui-disabled):not(.WithWarning) > svg.MuiSvgIcon-root,
label.MuiFormLabel-colorPrimary.Mui-focused,
svg.MuiCircularProgress-svg {
  @include themed {
    color: getThemeVariable('primaryMain');
  }
}

.Mui-disabled:not(.MuiStepLabel-iconContainer):not(.MuiStepLabel-root) svg.MuiSvgIcon-root,
.MuiButton-containedPrimary svg.MuiSvgIcon-root,
.WithWarning svg.MuiSvgIcon-root {
  color: inherit !important;
}

.MuiPopperUnstyled-root {
  z-index: 2 !important;
}

.MuiTableCell {
  &-root {
    font-weight: 400;
  }
  &-head {
    font-weight: 600;
    padding: 0;
  }
}

.MuiStepper-root {
  margin-top: 16px;
  margin-bottom: 40px;
}
