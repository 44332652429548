// from src/layout/Style.ts
$limeGreen: #cada20;
$brandGreenLight: #01b7ac;
$brandGreen: #2f998d;
$vividGreen: #34c7b1;
$blueGreen: #226073;
$darkGreen: #104859;
$brandViolet: rgba(36, 20, 79, 0.8);

$grayscale05: #fafafa;
$grayscale1: #2d3940;
$grayscale2: #505d64;
$grayscale3: #717d83;
$grayscale4: #b8c3c8;
$grayscale5: #9e97a0;
$grayscale6: #e8eded;
$grayscale7: #f7f9fa;

$form-background: #f9f9f9;
$devPrimaryMain: #33c9dc;
$devPrimaryMainLight: hsl(195, 95%, 80%);
$devSecondaryMain: #33bfff;
$systemDanger: #d83f2a; // used for error messages
$systemWarning: #ecb90d; // used for warning messages
$systemSuccess: #57a81a; // used for success messages

$themeSpacing: 8px;
$themeZIndexDrawer: 1200;
$loadingOverlayColor: rgba(0, 0, 0, 0.2);

$themes: (
  'production': (
    primaryMain: $brandGreen,
    secondaryMain: $grayscale3,
    primaryLight: $brandGreenLight,
  ),
  'development': (
    primaryMain: $devPrimaryMain,
    secondaryMain: $devSecondaryMain,
    primaryLight: $devPrimaryMainLight,
  ),
);

// copied from src/layout/themes.ts
@mixin pica_strong {
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 32px !important;
}

@mixin body {
  font-size: 17px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 25px !important;
}

@mixin body_strong {
  font-size: 17px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 24px !important;
}

@mixin greatPrimer {
  font-size: 22px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 32px !important;
}

@mixin pica {
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 30px !important;
}

// this function will duplicate the variables declared in it for any themes
@mixin themed() {
  @each $theme, $map in $themes {
    .theme--#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function getThemeVariable($key: 'primaryMain') {
  // defines the default value if none is given
  @return map-get($theme-map, $key);
}
