@import '../../theme.scss';

$themeSpacing2202: (2 * $themeSpacing) (2 * $themeSpacing) 0 (2 * $themeSpacing);

.aromaViz__ {
  &ingredientAnalysis {
    padding-bottom: $themeSpacing !important;
    margin-bottom: $themeSpacing !important;
    & > div {
      padding: (2 * $themeSpacing) !important;
    }
    & > p {
      padding: $themeSpacing2202 !important;
      margin-bottom: 0px !important;
    }
  }

  &block {
    width: 100% !important;
    margin-bottom: $themeSpacing !important;
    padding: $themeSpacing !important;
    margin-left: $themeSpacing !important;
    & .MuiAutocomplete-root.MuiAutocomplete-fullWidth {
      & .MuiOutlinedInput-notchedOutline {
        border-width: 0 !important;
        border-bottom: 1px solid !important;
      }
    }
  }

  &linearProgressRoot {
    display: flex !important;
    align-items: flex-start !important;
    justify-content: flex-start !important;
    position: absolute !important;
    width: 100% !important;
    z-index: 1 !important;
  }

  &linearProgress {
    width: (52 * $themeSpacing) !important;
    height: 3px !important;
    & .MuiLinearProgress-root {
      position: absolute !important;
      background-color: transparent !important;
      bottom: -17px !important;
    }
  }

  &versionSelector {
    & .MuiAutocomplete-root {
      padding-left: 0 !important;
      & .MuiOutlinedInput-root {
        padding-left: 0 !important;
        padding-right: $themeSpacing !important;
      }
    }
  }

  &container {
    margin-right: (2 * $themeSpacing) !important;
    z-index: 1 !important;
    min-height: 98px !important;
    & .MuiFormControl-root {
      display: block !important;
    }
  }

  &aromaProfile {
    min-height: 20vh !important;
    margin-bottom: (2 * $themeSpacing) !important;
    & > p {
      padding: $themeSpacing2202 !important;
      margin-bottom: 0px !important;
    }
  }

  &aromaData {
    margin-bottom: $themeSpacing !important;
    padding-bottom: (8.5 * $themeSpacing) !important;
    min-height: 20vh !important;
    & > p {
      padding: $themeSpacing2202 !important;
    }
  }

  &table {
    z-index: 1 !important;
  }

  &aromaComparison {
    min-height: 20vh !important;
    & > p {
      padding: $themeSpacing2202 !important;
      margin-bottom: 0 !important;
    }
  }

  &aromaWheelSvgWrapper {
    & svg {
      max-width: 100% !important;
      height: auto !important;
      //  maxHeight: 80vh !important;
    }
  }

  &canvas {
    max-width: calc(100vw - 275px) !important;
    min-height: 80vh !important;
    position: absolute !important;
    z-index: -1 !important;
  }

  &canvasPanel {
    position: relative !important;
  }
}
