.BreadCrumbs__ {
  &pathItemLast {
    padding: 8px !important;
    display: inline-block !important;
    color: #000 !important;
  }

  &pathItem {
    padding: 8px !important;
    display: inline-block !important;
    color: #444 !important;
  }
  &pathSeparator {
    padding: 8px !important;
    display: inline-block !important;
    color: #999 !important;
  }
}
