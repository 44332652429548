@import '../theme.scss';

.ProjectFlow__ {
  // copied from src/hooks/styles/use-project-flow-styles.ts
  &menuItem {
    padding-left: 5px !important;
    & .MuiOutlinedInput-input {
      padding: 6px 32px 6px 8px !important;
    }
  }

  &stepTitleWrapper {
    margin-bottom: 20px !important;
  }

  &sectionTitle {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }
  &sectionTitleWrapper {
    align-items: center !important;
  }

  &formGroup {
    border: 1px solid rgba(118, 118, 118, 30%) !important;
    padding: $themeSpacing !important;
    margin-bottom: $themeSpacing !important;
  }

  &button {
    margin-right: $themeSpacing !important;
  }

  &textArea {
    margin-top: (3 * $themeSpacing) !important;
    width: 50% !important;
    resize: none !important;
  }

  // copied from src/admin/projects/ProjectFlow/ProjectFlow.tsx
  &root {
    width: 100% !important;
    .ProjectFlow__stepper {
      background: transparent !important;
      .MuiStepLabel-iconContainer.Mui-active > svg {
        @include themed {
          color: getThemeVariable('primaryMain') !important;
        }
      }
      .MuiStepLabel-labelContainer {
        @include body;
      }
      .MuiStepLabel-labelContainer.Mui-completed {
        @include body;
      }
    }
  }

  &stepper {
    background: transparent !important;
  }

  &footerActionsBar {
    margin-top: 10px !important;
    text-align: center !important;
    padding: 10px !important;
    position: fixed !important;
    z-index: ($themeZIndexDrawer + 1) !important;
    top: auto !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
  }

  &backButton {
    margin-right: $themeSpacing !important;
  }
}
