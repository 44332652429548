@import '../../../../theme.scss';

.InputTypeSelector__ {
  &skeleton {
    margin: 0 $themeSpacing !important;
    width: 95px !important;
    height: 100% !important;
    transform: scale(1, 1) !important;
  }
}
