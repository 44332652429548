@import '../theme.scss';

// copied from frontends/cfi/src/hooks/styles/use-data-man-styles.ts

.DataMan__ {
  &columnCellWrapper {
    padding: 0 !important;
    border-left-style: solid !important;
    border-left-width: 1px !important;
    border-left-color: $grayscale6 !important;
    cursor: default !important;
    font-weight: 600 !important;
    &:first-child {
      border-left: 0 !important;
    }
    &:focus {
      outline: none !important;
    }
  }

  &columnFilterButton {
    visibility: hidden !important;
    opacity: 0.7 !important;
    padding: 20px 4px !important;
    cursor: pointer !important;
    &:hover {
      opacity: 1 !important;
    }
  }

  &columnCellSortable {
    &:focus {
      outline: dotted !important;
      outline-width: 1px !important;
      outline-offset: -2px !important;
      outline-color: red !important;
    }
    &:hover {
      cursor: pointer !important;
      background-color: $grayscale6 !important;
      .DataMan__columnFilterButton {
        visibility: visible !important;
      }
    }
  }

  & columnCellFilterable {
    &:focus {
      outline: dotted !important;
      outline-width: 1px !important;
      outline-offset: -2px !important;
      outline-color: red !important;
    }
    &:hover {
      background-color: $grayscale6 !important;
      .DataMan__columnFilterButton {
        visibility: visible !important;
      }
    }
  }

  &columnFilterActive {
    .DataMan__columnFilterButton {
      visibility: visible !important;
    }
  }

  &columnFilterButton {
    visibility: hidden !important;
    opacity: 0.7 !important;
    padding: 20px 4px !important;
    cursor: pointer !important;
    &:hover {
      opacity: 1 !important;
    }
  }

  &rowCellWrapper {
    font-weight: 400 !important;
  }

  &rowActionCellWrapper {
    white-space: nowrap !important;
  }

  &tableContainer {
    position: relative !important;
  }

  &clickableTableRow {
    &:hover {
      cursor: pointer !important;
      background-color: $grayscale7 !important;
    }
  }

  &tableLoading {
    opacity: 1 !important;
    position: absolute !important;
    left: 0 !important;
    right: 0 !important;
    top: 0 !important;
    bottom: 0 !important;
    background: $loadingOverlayColor !important;
  }

  &loadProgress {
    color: white !important;
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    margin-top: -32px !important;
    margin-left: -32px !important;
  }
}
