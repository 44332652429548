.Confirm__ {
  &loadProgress {
    color: black !important;
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    margin-top: -12px !important;
    margin-left: -12px !important;
  }
}
