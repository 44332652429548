@page {
  size: A4 !important;
  margin: 0 0 0 !important;
}

@media print {
  div {
    padding: 0 !important;
    margin: 0 !important;
    display: block !important;
  }

  body {
    margin: 0 !important;
  }

  html {
    width: 210mm !important;
    height: 297mm !important;
  }

  .print-page {
    box-sizing: border-box !important;
    page-break-after: always !important;
  }

  .print-page-grid {
    padding: 12.9mm 4.9mm !important;
    display: inherit !important;
    width: 210mm !important;
  }

  .barcode-item {
    display: inline-block !important;
    width: 99.1mm !important;
    height: 33.9mm !important;
    text-align: center !important;
    vertical-align: top !important;
    border: 1px solid transparent;
    border-radius: 1.5mm !important;
    box-sizing: border-box !important;
    svg {
      max-width: 98mm !important;
      max-height: 33mm !important;
    }
  }

  .barcode-item:nth-child(odd) {
    margin-right: 2mm !important;
  }
}

.IngredientBarcodes__ {
  &page {
    margin: 0 !important;
    border: initial !important;
    border-radius: initial !important;
    width: initial !important;
    min-height: initial !important;
    box-shadow: initial !important;
    background: initial !important;
    page-break-after: always !important;
    max-width: 100vw !important;
  }

  &grid {
    display: grid !important;
    grid-template-columns: 50% 50% !important;
    align-items: center !important;
    justify-items: center !important;
  }
}
